const ReportingReducer = (state = "2022", action) => {
  switch (action.type) {
    case "reporting_year":
      state = action.payload
      return state

    default:
      return state
  }
}

export default ReportingReducer
