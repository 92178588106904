import { combineReducers } from "redux"
import UserReducers from "./UserReducers"
import ReportingReducer from "./ReportingReducer"
import ReportUploadingReducer from "./ReportUploadingReducer"
import SubscriptionReducer from "./SubscriptionReducer"
import ReportingEntityReducer from "./ReportingEntityReducer"
import CompanyReducer from "./CompanyReducer"
import ScopeReducer from "./ScopeReducer"

const rootReducer = combineReducers({
	user: UserReducers,
	reporting: ReportingReducer,
	subscription: SubscriptionReducer,
	uploading: ReportUploadingReducer,
	reportingEntity: ReportingEntityReducer,
	company: CompanyReducer,
	ScopeData: ScopeReducer,
})

export default rootReducer
