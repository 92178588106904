import * as Realm from "realm-web"
import React, { useState } from "react"
const RealmAppContext = React.createContext()

export const useRealmApp = () => {
	const app = React.useContext(RealmAppContext)
	if (!app) {
		throw new Error(`You must call useRealmApp() inside of a <RealmAppProvider />`)
	}
	return app
}

export const RealmAppProvider = ({ appId, children }) => {
	const [app, setApp] = useState(new Realm.App(appId))
	const [currentUser, setCurrentUser] = useState(app.currentUser)

	React.useEffect(() => {
		setApp(new Realm.App(appId))
	}, [appId])

	// https://docs.mongodb.com/realm/web/authenticate/#custom-jwt
	async function loginJWT(jwt) {
		try {
			// login user in Realm
			const credentials = Realm.Credentials.jwt(jwt) // construct the credentials for Realm
			await app.logIn(credentials)

			// `App.currentUser` updates to match the logged in user
			// assert(user.id === app.currentUser.id);

			setCurrentUser(app.currentUser)
			localStorage.setItem("userId", app.currentUser.id)
			localStorage.setItem("token", app.currentUser._accessToken)
			return app.currentUser
		} catch (error) {
			return null
		}
	}

	async function getRealmUserData(netlifyId) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const user = await app.currentUser.functions.getUserByNetlifyIdFunction({
				netlifyID: netlifyId,
			})
			return user
		} catch (error) {}
	}

	async function logout() {
		try {
			localStorage.removeItem("userId")
			localStorage.removeItem("token")
			localStorage.removeItem("netlifyId")
			await app.currentUser?.logOut()
			setCurrentUser(app.currentUser)
		} catch (error) {}
	}

	async function getChartScopeData(RegistrationNumber, year) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			let scopeChartData = await app.currentUser.functions.getScopeEmissionChartDataFunction({
				RegistrationNumber,
				year,
			})
			return scopeChartData
		} catch (error) {}
		return { success: false }
	}

	async function getTopSuppliers(RegistrationNumber, year) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const topSuppliers = await app.currentUser.functions.GetTopSuppliers({
				RegistrationNumber,
				year,
			})
			return topSuppliers
		} catch (error) {}
		return { success: false }
	}

	async function getScopeCategoryData(RegistrationNumber, year) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const scopeCategoryData = await app.currentUser.functions.GetScopeCategoryData({
				RegistrationNumber: RegistrationNumber,
				year,
			})
			return scopeCategoryData
		} catch (error) {}
		return { success: false }
	}

	async function getYearlyChartData(RegistrationNumber) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.getYearlyEmission({
				RegistrationNumber: RegistrationNumber,
			})
			return data
		} catch (error) {
			return { success: false }
		}
	}

	async function dashboardCO2AddedData(RegistrationNumber, year) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const scopeCategoryData = await app.currentUser.functions.getCO2AddedDataFunction({
				RegistrationNumber,
				year,
			})
			return scopeCategoryData
		} catch (error) {}
		return { success: false }
	}

	async function getTransactions(input) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const transactions = await app.currentUser.functions.getTransactionsFunction(input)
			return transactions
		} catch (error) {
			return { success: false }
		}
	}

	async function insertNewSupplier(input) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.insertNewSupplierFunction(input)
			return data
		} catch (error) {}
		return { success: false }
	}

	const updateSupplier = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.updateSupplierFunction(input)
			return data
		} catch (error) {
			return error
		}
	}

	const updateMultipleTransactions = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.updateMultipleTransactions(input)
			return data
		} catch (error) {
			return error
		}
	}

	async function getCompanyEmission(RegistrationNumber, year) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const company = await app.currentUser.functions.getCompanyEmission({ RegistrationNumber, year })
			return company
		} catch (error) {}
		return { success: false }
	}

	async function getTriggerProgress(RegistrationNumber, userId) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const company = await app.currentUser.functions.getTriggerProgress({ RegistrationNumber, userId })
			return company
		} catch (error) {}
		return { success: false }
	}

	async function updateCompanyEmission(input) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const company = await app.currentUser.functions.updateCompanyEmission(input)
			return company
		} catch (error) {}
		return { success: false }
	}

	async function getRevenue(input) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const company = await app.currentUser.functions.getRevenueForYear(input)
			return company
		} catch (error) {}
		return { success: false }
	}

	async function setRevenue(input) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const result = await app.currentUser.functions.setRevenueForYear(input)
			return result
		} catch (error) {}
		return { success: false }
	}

	const getSuppliersList = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const result = await app.currentUser.functions.getSuppliersList(input)
			return result
		} catch (error) {
			return error
		}
	}

	const getSuppliers = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const suppliers = await app.currentUser.functions.getSuppliersFunction(input)
			return suppliers
		} catch (error) {
			return error
		}
	}

	async function createCompany(input) {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const result = await app.currentUser.functions.createCompany(input)
			return result
		} catch (error) {
			return error
		}
	}

	async function getRealmUserByOrgId(orgId) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const user = await app.currentUser.functions.getUserByOrganizationIdFunction({
				organizationId: orgId,
			})
			return user
		} catch (error) {}
	}

	async function getLastUploadedTransactionDate(RegistrationNumber) {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.getlastUploadedTransactionDate({
				RegistrationNumber,
			})
			return data
		} catch (err) {}
	}

	async function updateCompanyInfo(RegistrationNumber, data) {
		try {
			// call realm function to get user data from user collection
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const user = await app.currentUser.functions.updateCompanyInfo({
				RegistrationNumber,
				data,
			})
			return user
		} catch (error) {}
	}

	async function getMonthlyChartData(RegistrationNumber, year) {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.getMonthlyEmissions({ RegistrationNumber, year })
			return data
		} catch (error) {
			return error
		}
	}

	const addNewTransaction = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.insertNewTransactionFunction(input)
			return data
		} catch (error) {
			return error
		}
	}

	const lockData = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.lockData(input)
			return data
		} catch (error) {
			return error
		}
	}

	const unlockData = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}

			const data = await app.currentUser.functions.unLockData(input)
			return data
		} catch (error) {
			return error
		}
	}

	const getDataStatus = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.getLockedDataInfo(input)
			return data
		} catch (error) {
			console.log(error)
			return error
		}
	}

	const getLockedDataInfo = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.getCompanyLockedDataInfo(input)
			return data
		} catch (error) {
			return error
		}
	}

	const updateTransaction = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.updateTransactionFunction(input)
			return data
		} catch (error) {
			return error
		}
	}

	const updateAccountID = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.rerunEmissionTransactionFunction(input)
			return data
		} catch (error) {
			throw error
		}
	}

	const updateTransactionScope = async (input) => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.UpdateTransactionScopeFunction(input)
			return data
		} catch (error) {
			throw error
		}
	}

	const getNace = async () => {
		try {
			if (!app.currentUser._accessToken) {
				app.currentUser._accessToken = localStorage.getItem("token")
			}
			const data = await app.currentUser.functions.getNaceFunction()
			return data
		} catch (error) {
			return error
		}
	}

	const wrapped = {
		...app,
		currentUser,
		loginJWT,
		logout,
		getTriggerProgress,
		getRealmUserData,
		getChartScopeData,
		getTopSuppliers,
		getScopeCategoryData,
		dashboardCO2AddedData,
		getTransactions,
		getRevenue,
		setRevenue,
		insertNewSupplier,
		getNace,
		createCompany,
		addNewTransaction,
		getCompanyEmission,
		updateCompanyEmission,
		getYearlyChartData,
		getRealmUserByOrgId,
		updateCompanyInfo,
		updateAccountID,
		updateTransaction,
		updateTransactionScope,
		getLastUploadedTransactionDate,
		getMonthlyChartData,
		getSuppliers,
		getSuppliersList,
		updateSupplier,
		updateMultipleTransactions,
		lockData,
		getDataStatus,
		unlockData,
		getLockedDataInfo,
	}

	return <RealmAppContext.Provider value={wrapped}>{children}</RealmAppContext.Provider>
}
