const ReportingEntityReducer = (state = false, action) => {
	switch (action.type) {
		case "OPEN_SUBSCRIPTION_MODAL":
			state = true
			return state
		case "CLOSE_SUBSCRIPTION_MODAL":
			state = false
			return state
		default:
			return state
	}
}

export default ReportingEntityReducer
