const ReportUploadingReducer = (state = false, action) => {
	switch (action.type) {
		case "working_on_data":
			state = action.payload
			return state

		default:
			return state
	}
}

export default ReportUploadingReducer
