const ScopeReducer = (state = {}, action) => {
	switch (action.type) {
		case "SET_SCOPE_DATA":
			state = action.payload
			return state
		default:
			return state
	}
}

export default ScopeReducer
