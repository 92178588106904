const initialState = {
	user: {},
	currentOrganization: {},
	refetchOrg: false,
	fileUploaded: false,
}

export default function UserReducers(state = initialState, action) {
	/* eslint eqeqeq: 0 */
	if (action.type == "user_data") {
		return {
			...state,
			user: action.payload,
		}
	}
	if (action.type == "current_organization") {
		return {
			...state,
			currentOrganization: action.payload,
		}
	}
	if (action.type == "refetch_org") {
		return {
			...state,
			refetchOrg: action.payload,
		}
	}
	if (action.type == "file_uploaded") {
		return {
			...state,
			fileUploaded: action.payload,
		}
	}
	return state
}
