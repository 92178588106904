import React from "react"
import { useRealmApp } from "../realm/RealmAppProvider"
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider } from "@apollo/client"
import { GraphQLClient } from "graphql-request"

const createRealmApolloClient = (app) => {
	const link = new HttpLink({
		// Realm application graphql url
		uri: `https://eu-west-1.aws.realm.mongodb.com/api/client/v2.0/app/${app.id}/graphql`,

		fetch: async (uri, options) => {
			if (!app.currentUser) {
				throw new Error(`Must be logged in to use the GraphQL API`)
			}

			await app.currentUser.refreshCustomData()

			options.headers.Authorization = `Bearer ${app.currentUser.accessToken}`
			return fetch(uri, options)
		},
	})

	const cache = new InMemoryCache()

	return new ApolloClient({ link, cache })
}

export default function RealmApolloProvider({ children }) {
	const app = useRealmApp()
	const [client, setClient] = React.useState(createRealmApolloClient(app))
	React.useEffect(() => {
		setClient(createRealmApolloClient(app))
	}, [app])
	return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export function useGraphqlClient() {
	const app = useRealmApp()

	let token = app?.currentUser?._accessToken

	if (!app?.currentUser?._accessToken) {
		token = localStorage.getItem("token")
	}

	const graphQLClient = new GraphQLClient(
		`https://eu-west-1.aws.realm.mongodb.com/api/client/v2.0/app/${app.id}/graphql`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	)
	return { graphQLClient }
}
