const CompanyReducer = (state = true, action) => {
	switch (action.type) {
		case "COMPANY_REGISTERED":
			state = true
			return state
		case "COMPANY_NOT_REGISTERED":
			state = false
			return state
		default:
			return state
	}
}

export default CompanyReducer
