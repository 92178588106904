import { createStore } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import rootReducer from "./reducers"

const persistConfig = {
	key: "root",
	whitelist: ["reporting", "user", "ScopeData"], // only these will be persisted, add other reducers if needed
	storage: storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer)
export const persistor = persistStore(store)
