import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import Backend from "i18next-http-backend"
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const Languages = ["no", "en"]

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: "no",
		// debug: true,
		detection: {
			order: ["querystring", "cookie", "localStorage", "sessionStorage"],
		},
		whitelist: Languages,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	})

const userLanguage = localStorage.getItem("userLanguage")
if (userLanguage) {
	i18n.changeLanguage(userLanguage)
} else {
	i18n.changeLanguage("no") // Default language
}

export default i18n
